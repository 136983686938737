<template lang="">
  <v-col
    cols="12"
    :md="12-data.MediaColumnWidth"
    class="textblock"
  >
    <div
      class="contenttext"
      v-html="processedContent()"
    />
  </v-col>
  <v-col
    cols="12" 
    :md="data.MediaColumnWidth" 
    class="imgblock" 
    :order="data.Invert ? 'first' : 'last'"
  >
    <div
      v-if="data.MainMedia.data != null && data.MainMedia.data.attributes.mime.startsWith('image/')"
      class="imagecontainer"
    >
      <v-lazy-image
        v-if="data.HoverOrPlaceholderMedia.data != null" 
        :src="$api + $rih(data.HoverOrPlaceholderMedia,'orig').url" 
        :src-placeholder="$api + $rih(data.HoverOrPlaceholderMedia,'thumbnail').url" 
        :srcset="
          $api + $rih(data.HoverOrPlaceholderMedia,'small').url+' 500w,'+
            $api + $rih(data.HoverOrPlaceholderMedia,'medium').url+' 750w,'+
            $api + $rih(data.HoverOrPlaceholderMedia,'large').url+' 1000w,'+
            $api + $rih(data.HoverOrPlaceholderMedia,'xlarge').url+' 1900w,'+
            $api + $rih(data.HoverOrPlaceholderMedia,'orig').url+' 2560w'
        "
        sizes="(min-width: 1600px) 1200px,
                         (min-width: 1400px) 1100px,
                         (min-width:1000px) 900px,
                         100vw"
        class="hoverimg"
      />
      <v-lazy-image
        v-if="data.MainMedia.data != null" 
        :alt="data.MainMedia.data.attributes.alternativeText || 'no alt'"
        :src="$api + $rih(data.MainMedia,'orig').url" 
        :src-placeholder="$api + $rih(data.MainMedia,'thumbnail').url" 
        :srcset="
          $api + $rih(data.MainMedia,'small').url+' 500w,'+
            $api + $rih(data.MainMedia,'medium').url+' 750w,'+
            $api + $rih(data.MainMedia,'large').url+' 1000w,'+
            $api + $rih(data.MainMedia,'xlarge').url+' 1900w,'+
            $api + $rih(data.MainMedia,'orig').url+' 2560w'
        "
        sizes="(min-width: 1600px) 1200px,
                         (min-width: 1400px) 1100px,
                         (min-width:1000px) 900px,
                         100vw"
      />
    </div>
    <div
      v-if="data.MainMedia.data != null && data.MainMedia.data.attributes.mime.startsWith('video/')"
      class="videocontainer"
    >
      <VideoPlayer
        :src="'https://jw-api.commcentral.de' +data.MainMedia.data.attributes.url"
        :poster="data.HoverOrPlaceholderMedia.data != null? 'https://jw-api.commcentral.de' +data.HoverOrPlaceholderMedia.data.attributes.url : 'https://jw-api.commcentral.de/uploads/placeholder_fd9d291497.png'"
        :loop="true"
        :autoplay="data.VideoAsGif ? 'muted' : false"
        :volume="0.1"
        :controls="data.VideoAsGif ? false : true"
        fluid
        responsive
        native-controls-for-touch
      />
    </div>
  </v-col>
</template>
<script>
import VLazyImage from "v-lazy-image";
import { VideoPlayer } from '@videojs-player/vue'
import 'video.js/dist/video-js.css'

export default {
    components: {
        VLazyImage,
        VideoPlayer
    },  
    props: {
        data: Object,
        hlcolor: String,
    },
    computed: {
        hlcolorOpacity: function() {
            return this.hlcolor+"2F"
        }
    },
    methods: {
        processedContent: function() {
            if(this.data.ContentText != null) return this.data.ContentText.replace(/<img src="\//gi,'<img src="'+this.$api+'/')
            else return null
        }
    }
}
</script>
<style scoped>
    .contenttext {
        font-family: ibmplexsans-regular;
    }     

    .imgblock {
        position:relative;
        top:0;
        left:0;
    }

    .imgblock img {        
        width: 100%; /* or any custom size */
        height: 100%; 
        object-fit: contain;
    }

    .imagecontainer img {
        width:100%;
        height:100%;
    }

    .hoverimg {
        position: absolute;
        opacity: 0;
        transition: opacity 0.3s;
        padding-right:24px;
        padding-bottom:30px;
    }

    .hoverimg:hover {
        opacity: 1.0;
        transition: opacity 0.3s;
    }

    ::v-deep .contenttext img, ::v-deep .contenttext p {
        width:100%;
        max-width: 100%;
    }

    ::v-deep h2, .contenttext h2 {
        font-size: 32px;
        font-family:ibmplexsans-bold;
        line-height: 38px;
    }

    ::v-deep h3, .contenttext h3 {
        font-family:ibmplexsans-bold;
    }

    ::v-deep .contenttext a {
        color: gray;
        font-family: ibmplexsans-italic;
        text-decoration: none;
    }
    ::v-deep .contenttext a:after {
        content: " ↗";
        color: v-bind(hlcolor);
        font-family: ibmplexsans-regular;
        text-decoration: none;
    } 

    ::v-deep .contenttext a.noarrow:after {
        content: none;
        color: inherit;
        font-family: ibmplexsans-regular;
        text-decoration: none;
    }

    ::v-deep .contenttext a:after:hover {
        text-decoration: none;
    }  

    ::v-deep .contenttext a:hover {
        text-decoration: none;    
        background-color: v-bind(hlcolorOpacity);    
    } 
</style>