import { createApp } from 'vue'
import App from './App.vue'

import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { dom } from '@fortawesome/fontawesome-svg-core'

import { library } from '@fortawesome/fontawesome-svg-core'
library.add(fas)
library.add(fab)

import VueMatomo from 'vue-matomo'

import './assets/sass/font.scss'

import { VueMasonryPlugin } from "vue-masonry";
import vuetify from './plugins/vuetify'

import $bus from './eventbus.js';

import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'
import router from './router'

import get from 'lodash/get'


let resImageHelper = function(obj, targetSize){
    //fallback - no formats available
    if(obj.data.attributes.formats == undefined 
        || obj.data.attributes.formats == null 
        || obj.data.attributes.formats.length == 0) return obj.data.attributes;

    //enumerate formats
    let available = Object.keys(obj.data.attributes.formats);
    //console.log("Processing "+obj.data.attributes.name+", target: "+targetSize+", available: "+JSON.stringify(available))
    let formats = ["thumbnail", "xsmall", "small", "medium", "large", "xlarge", "orig"];
    
    //simple case - requested size available
    if(available.includes(targetSize)) {
        //console.log(". target size match!")
        return obj.data.attributes.formats[targetSize]
    }

    //follow up: return next bigger if size not available
    //console.log(". target size not found, fallback:")
    let reqIdx = formats.indexOf(targetSize);
    for (let i = reqIdx+1; i < formats.length; i++) {
        //console.log(".. check "+formats[i]+" vs "+JSON.stringify(available))
        if(available.includes(formats[i])) return obj.data.attributes.formats[targetSize];
    }

    //fallback: return raw uploaded image obj
    //console.warn(". no appropriate size found, return original")
    return obj.data.attributes;
} 

const app = createApp(App)

app.use(router)
app.use(vuetify)
app.use(VueVideoPlayer)
app.use(VueMasonryPlugin)
app.use(VueMatomo, {
    // Configure your matomo server and site by providing
    host: 'https://florian-geiselhart.de/matomo',
    siteId: 2,
})


app.config.globalProperties.$bus = $bus;
app.config.globalProperties.$dom = dom;
app.config.globalProperties.$rih = resImageHelper;
app.config.globalProperties.$_get = get;
app.config.globalProperties.$api = 'https://jw-api.commcentral.de';

//app.component('font-awesome-icon', FontAwesomeIcon)

app.mount('#app')

dom.watch()