<template>
  <!-- Burger menu -->
  <div
    class="burger"
    :class="{ drawerOpen: drawerOpen }"
    style="z-index:30000"
    @click="toggleDrawer"
  >
    <div />
    <div />
    <div />
  </div>

  <!-- Drawer container with single drawers in it -->
  <div
    class="drawerWrapper" 
    style="z-index:30001"
  >
    <div
      id="drawerContainer_id"
      v-resize="onResize"
      class="drawerContainer"
      :class="{ drawerOpen: drawerOpen, maximized: drawerMaximized }"
      @click="closeDrawer"
    >
      <div
        id="d3"
        class="arbeiten_title drawer"        
        @click="closeDrawer"                
      >
        Arbeiten
      </div>

      <Drawer
        v-once
        id="d2"
        class="drawer shadow"
        :pos="2"
        content-id="impressum"
        :width="globalWidthPxStr"
        title="Kleingedrucktes"
        @maximized="onMaximizedDrawer"
        @minimized="onMinimizedDrawer"
      />
      <Drawer
        v-once
        id="d1"
        class="drawer"
        :pos="1"
        content-id="kontakt"
        :width="globalWidthPxStr"
        title="Kontakt"
        @maximized="onMaximizedDrawer"
        @minimized="onMinimizedDrawer"
      />
      <Drawer
        v-once
        id="d0"
        ref="d0"
        class="drawer"
        :pos="0"
        content-id="ueber-mich"
        :width="globalWidthPxStr"
        title="Über mich"
        @maximized="onMaximizedDrawer"
        @minimized="onMinimizedDrawer"
      />
    </div>
  </div>
</template>

<script>
    import Drawer from './Drawer.vue'
    import gsap from 'gsap'

    export default {
        name: 'DrawerContainer',
        components: {
            Drawer
        },
        data() {
            return {
                drawerOpen: false,
                drawerMaximized: false,
                drawerIdMaximized: -1,
                openCloseTween: undefined,
                globalWidthPx: 70,
                windowSize: 0
            }
        },
        computed: {
            globalWidthPxStr: function () {
                return this.globalWidthPx + 'px'
            }
        },
        mounted() {
            if (this.drawerOpen) this.$bus.trigger("drawerOpen");  
            this.prepareAnimes();          
        },
        methods: {
            prepareAnimes: function() {
                //prepare menu show animation
                this.openCloseTween = gsap.from(".drawerContainer", {
                    duration: 0.3,
                    left: '-300px',
                    onReverseComplete: () => {
                        this.drawerOpen = !this.drawerOpen
                    }
                })
                this.openCloseTween.pause();

                //prepare maximize animation
                for (let i = 0; i < 4; i++) {
                    //set initial layered positions
                    gsap.set("#d" + i, {
                        left: this.computeLeftPos(i),
                    }, 0)
                }
                gsap.set(".drawer", {
                    maxHeight: "90%",
                }, 0)
            },
            computeLeftPos: function (idpos) {
                //return 'calc(-100vw + '+(idpos*this.globalWidthPx+this.globalWidthPx+(idpos*3))+'px)'
                return -this.$refs.d0.$el.clientWidth + (idpos * this.globalWidthPx + this.globalWidthPx + (
                    idpos * 3)) + 'px'
            },
            toggleDrawer: function () {
                if (!this.drawerOpen) {
                    this.drawerOpen = !this.drawerOpen
                    this.$bus.trigger("drawerOpen");
                    this.openCloseTween.play();
                } else {
                    this.$bus.trigger("drawerClose");
                }
            },
            closeDrawer: function (e) {
                if (e.target.id == "drawerContainer_id" || e.target.id == "d3") {
                    this.$bus.trigger("drawerClose");
                    
                    if (this.$vuetify.display.mdAndUp) {
                        //first close open tabs @ desktop, then close drawer
                        for (let i = 0; i < 3; i++) {
                            gsap.to("#d" + i, {
                                duration: 0.3,
                                left: this.computeLeftPos(i),
                            })
                        }
                        gsap.to("#d3", {
                            duration: 0.3,
                            left: this.computeLeftPos(3),
                            onComplete: () => {
                                this.drawerCloseAnimation();
                            }
                        })
                    } else {
                        //directly close drawer
                        this.drawerCloseAnimation();
                    }
                }
            },
            drawerCloseAnimation: function () {
                this.openCloseTween.reverse();
            },
            onMaximizedDrawer: function (e) {
                //Setup animation
                let prevMaximized = this.drawerIdMaximized;
                this.drawerIdMaximized = e;

                //Check breakpoint
                let tl = gsap.timeline({ defaults: { overwrite: false } });
                if (this.$vuetify.display.mdAndUp) {
                    //--- Handle desktop maximize & responsiveness
                    if (this.drawerMaximized) {
                        //minimize previous - all
                        for (let i = 0; i < e; i++) {
                            if(i == e) continue; //for the one to be maximized, skip animation (done below)
                            tl.to("#d" + i, {
                                duration: 0.3,
                                left: this.computeLeftPos(i),
                                delay: 0.15
                            }, 0)
                        }
                    }
                    //Handle all tabs to the right of current tab (have to be animated as well)                   
                    for (let i = e + 1; i < 4; i++) {
                        tl.to("#d" + i, {
                            duration: 0.3,
                            left: (i) * this.globalWidthPx + 'px',
                            delay: 0.15,
                            overwrite: "auto",
                        }, 0)
                    }
                    //Handle current tab
                    tl.to("#d" + e, {
                        duration: 0.3,
                        left: e * this.globalWidthPx + 'px',
                        delay: 0.15
                    }, 0)
                } else {
                    //--- Handle mobile maximize
                    for (let i = 0; i < e; i++) {
                        tl.to("#d" + i, {
                            duration: 0.3,
                            left: '-=' + (e + 1) * this.globalWidthPx + 'px',
                            delay: 0.15
                        }, 0)
                    }                    
                    for (let i = e + 1; i < 4; i++) {
                        tl.to("#d" + i, {
                            duration: 0.3,
                            left: (i - e) * this.globalWidthPx + 'px',
                            delay: 0.15
                        }, 0)
                    }
                    tl.to("#d" + e, {
                        duration: 0.3,
                        left: '0px',
                        delay: 0.15
                    }, 0)
                }
                tl.to(".drawer", {
                    duration: 0.15,
                    maxHeight: "100%",
                    delay: 0,
                }, 0)
                this.drawerMaximized = true;
            },
            onMinimizedDrawer: function () {
                this.drawerMaximized = false;
                this.drawerIdMaximized = -1;
                //Setup animation  
                this.tl = gsap.timeline();
                this.tl.to(".drawer", {
                    duration: 0.15,
                    maxHeight: "90%",
                    delay: 0,
                }, 0)
                for (let i = 0; i < 4; i++) {
                    this.tl.to("#d" + i, {
                        duration: 0.3,
                        left: this.computeLeftPos(i),
                        delay: 0.15
                    }, 0)
                }
            },
            onResize () {
                this.windowSize = { x: window.innerWidth, y: window.innerHeight }             
                if(this.drawerMaximized) {
                    //this.onMaximizedDrawer(this.drawerIdMaximized);
                    if (this.$vuetify.display.mdAndUp) {
                        for (let i = 4; i >= 0; i--) {
                            if(i < this.drawerIdMaximized) gsap.set("#d" + i, {left: this.computeLeftPos(i)})
                            else gsap.set("#d" + i, {left: (i) * this.globalWidthPx + 'px'})
                        }
                    } else {
                        for (let i = 4; i >= 0; i--) {
                            if(i < this.drawerIdMaximized) gsap.set("#d" + i, {left: '-=' + (this.drawerIdMaximized + 1) * this.globalWidthPx + 'px'})
                            else if(i == this.drawerIdMaximized) gsap.set("#d" + i, {left: '0px'})
                            else gsap.set("#d" + i, {left: (i - this.drawerIdMaximized) * this.globalWidthPx + 'px'})
                        }
                    }
                }
                else {
                    for (let i = 0; i < 4; i++) {
                        gsap.set("#d" + i, {left: this.computeLeftPos(i)})
                    }
                }
            },
        }
    }
</script>

<style>
    .burger {
        display:flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 10vh;
        height: 10vh;
        opacity: 1;
        transition: opacity 0.3s;
        cursor: pointer;
    }

    .burger.drawerOpen {
        opacity: 0;
        transition: opacity 0.3s;
        pointer-events: none;
    }

    .burger div {
        width: 0.4em;
        height: 2.2em;
        background-color: white;
        display: inline-block;
        border-radius: 0.2em;
        margin-right: 0.25em;
        margin-left: 0.25em;
        /*box-shadow: 1px 1px 8px #000000;*/
    }

    .drawer {
        display: block;
        max-height: 90%;
        height: 100%;
        width: v-bind(globalWidthPxStr);
        position: absolute;
        top: 0;
        /*everything else comes from within drawer*/
    }

    .drawerContainer {
        /*display: none;*/
        width: 100vw;
        overflow-x: hidden;        
        height: -webkit-fill-available;        
        height: fill-available;
        height: 100%;
        margin: 0;
        padding: 0;
        position: fixed;
        top: 0;
        left: 0;
        text-align: left;
        pointer-events: none;
    }

    .drawerContainer.drawerOpen {
        /*display: block;*/
        pointer-events: fill;
    }

    .drawerWrapper {        
        height: -webkit-fill-available;        
        height: fill-available;
        height: 100%;
    }

    #d0,
    #d1,
    #d2,
    #d3 {
        /*max-height:90vh;*/
    }

    .shadow {
        box-shadow:  5px 0px 23px 11px rgba(0,0,0,0.25);
    }

    .arbeiten_title {
        display: flex;
        align-items: flex-end;
        width:1500px;
        max-width: calc(100vw - 350px);
        line-height: 4.5rem;
        font-size: 2rem;
        font-weight: 900;
        color: white;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        text-align: left;
        padding-top: 1rem;
        position: absolute;
        top: 0;
        left: 210px;
        transform: rotate(-180deg);
    }

    @media (max-width: 959px) {
        .arbeiten_title {
            max-width: 100vw;
        }
    }
</style>