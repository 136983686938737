<template>
  <!--- Content -->
  <v-carousel
    v-model="currentProjectIndex"
    class="ps"
    continuous
    height="100%"
    hide-delimiters
    :show-arrows="false"
    :touch="{up: onSwipeUp, down: onSwipeDown, left: next, right: prev}"
  >
    <v-carousel-item
      v-for="(item,i) in projectList"
      :key="i"
      class="ps_item"
      eager
    >
      <Project
        :id="item.id"
        :sid="i"
        :isactive="i == currentProjectIndex"
        class="p"
        bgcol="#ff0000"
        eager
        @more-opened="globalMoreState = true"
        @more-closed="globalMoreState = false"
      />
    </v-carousel-item>
  </v-carousel>
  <!--- Nav -->
  <div
    class="ps_overlay"
    :class="{invisible: globalMoreState || globalDrawerState}"
  >
    <span
      id="proj_arrow_right"
      class="proj_arrow"
      @click="next()"
    >→</span>
    <span
      id="proj_arrow_left"
      class="proj_arrow"
      @click="prev()"
    >←</span>
  </div>
  <!-- img preloader for placeholders -->
  <div
    style="display:none; position:absolute; left:-2000; top:-2000; width:0; height:0; opacity: 0; z-index: -99999;"
  >
    <span
      v-for="(item,i) in projectList"
      :key="i"
    >
      <img :src="$api + item.attributes.MainImage.data.attributes.formats.small.url">
      <span>{{ JSON.stringify(item.attributes.MainImage.data.attributes.formats.small.url) }}</span>
    </span>
  </div>
</template>

<script>
    import Project from './Project.vue'
    import axios from 'axios';

    export default {
        name: 'ProjectsComp',
        components: {
            // eslint-disable-next-line
            Project
        },
        data() {
            return {
                projectList: [],
                currentProjectIndex: -1,
                globalMoreState: false,
                globalDrawerState: false,
            }
        },
        mounted() {
            axios.get(this.$api + '/api/projects?sort=Sorting&populate=deep,2').then(response => {
                this.projectList = response.data.data;
                //set first as active unless specific requested
                let uri = window.location.search.substring(1);
                let params = new URLSearchParams(uri);
                let slide = params.get("slide");
                if (slide != null) this.currentProjectIndex = parseInt(slide);
                else this.currentProjectIndex = 0;
                this.$root.curID = this.currentProjectIndex;
                if(this.$matomo) this.$matomo.trackPageView("Landing")
            });
            this.$bus.on("drawerOpen", () => {
                this.globalDrawerState = true;
            })
            this.$bus.on("drawerClose", () => {
                this.globalDrawerState = false;
            })
        },
        methods: {
            next() {
                if (this.globalMoreState) return;                
                this.currentProjectIndex = this.currentProjectIndex + 1 === this.projectList.length ?
                    0 :
                    this.currentProjectIndex + 1             
                this.$matomo.trackPageView(this.projectList[this.currentProjectIndex].attributes.Title)
                this.$root.curID = this.currentProjectIndex;
            },
            prev() {
                if (this.globalMoreState) return;
                this.currentProjectIndex = this.currentProjectIndex - 1 < 0 ?
                    this.projectList.length - 1 :
                    this.currentProjectIndex - 1
                this.$matomo.trackPageView(this.projectList[this.currentProjectIndex].attributes.Title)
                this.$root.curID = this.currentProjectIndex;
            },
            onSwipeUp() {
                this.$bus.trigger("swipeToOpen");
            },
            onSwipeDown() {
                this.$bus.trigger("swipeToClose");
            },
            updateElementsAfterIdUpdate: function () {

            },
        }
    }
</script>

<style>
    .ps {
        min-height: 100%;
        height:100%;
        width: 100vw;
        margin: 0;
        padding: 0;
        position: fixed;
        top: 0;
        left: 0;
        background-color: black;
    }

    .ps .v-window__container {
        min-height: 100%;
    }
    
    .ps-item {
        min-height: 100%;
    }

    .ps_overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        font-size: 30pt;
        color: white;
        z-index: 9000;
        pointer-events: none;
        opacity: 1.0;
        transition: opacity 0.5s;
        user-select: none;
        cursor: pointer;
    }

    .p {
        display: inline-block;
        width: 100vw;
    }

    .sl_button .v-btn__content {
        color: white !important;
        font-size: 24pt;
        pointer-events: auto;
    }

    .proj_arrow {
        display: block;
        position: absolute;
        top: 45vh;
        padding-left: 1vw;
        padding-right: 1vw;
        user-select: none;
        /*text-shadow: 1px 1px 8px #555555;*/
        pointer-events: auto;
    }

    .invisible .proj_arrow {
        pointer-events: none;
    }

    #proj_arrow_right {
        right: 0px;
    }

    .invisible {
        pointer-events: none;
        opacity: 0.0;
        transition: opacity 0.5s;
    }
</style>