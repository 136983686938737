<template>
  <v-app>
    <v-main>
      <Projects />
      <DrawerContainer />
    </v-main>
  </v-app>
</template>

<script>
  import DrawerContainer from './components/DrawerContainer.vue'
  import Projects from './components/Projects.vue'

  export default {
    name: 'App',
    components: {
      // eslint-disable-next-line
      DrawerContainer,
      Projects,
    },
    computed: {
      mode() {
        return process.env.NODE_ENV;
      }
    },
    mounted() {
      console.log(process.env.NODE_ENV);
      this.$dom.i2svg() 
    }
  }
</script>

<style>
  #app {
    font-family: ibmplexsans-bold, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin: 0;
    padding: 0;
  }

  body {
    margin: 0;
    padding: 0;
    color: white;
    background-color: black;
    min-height: 100vh;
    min-height: fill-available;
    min-height: -webkit-fill-available;
    min-height: -moz-available;
    overflow-y: hidden;
  }

  html {
    height: fill-available;
    height: -webkit-fill-available;
    height: -moz-available;
  }

  h4 {
    font-family: ibmplexsans-bold, Helvetica, Arial, sans-serif;
    font-weight:normal;
  }
</style>