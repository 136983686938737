<template>
  <div class="container">
    <div
      :id="'pc'+sid"
      class="catWrapper align-center justify-center d-flex"
      :class="{ 'opacity0': drawerOverlay, 'opacity1': !drawerOverlay }"
      @click="toggleMore"
    >
      <span
        :style="{color: projectData.TextfarbeBild}"
        class="cat d-md-none"
        :class="{shrink: moreOpened && (getNumCats(projectData.CategoryMulti) >= 3)}"
        v-html="formatCats(projectData.CategoryMulti, true)"
      />
      <span
        :style="{color: projectData.TextfarbeBild}"
        class="cat d-none d-md-block"
        v-html="formatCats(projectData.CategoryMulti, false)"
      />
    </div>
    <v-lazy-image
      v-if="loaded"
      :src="$api + $rih(projectData.MainImage,'orig').url"
      :src-placeholder="$api + $rih(projectData.MainImage,'small').url"
      :srcset="
        $api + $rih(projectData.MainImage,'small').url+' 500w,'+
          $api + $rih(projectData.MainImage,'medium').url+' 750w,'+
          $api + $rih(projectData.MainImage,'large').url+' 1000w,'+
          $api + $rih(projectData.MainImage,'xlarge').url+' 1900w,'+
          $api + $rih(projectData.MainImage,'orig').url+' 2560w'
      "
      sizes="100vw"
      :alt="projectData.MainImage.data.attributes.alternativeText || 'no alt'"
      :style="{objectPosition: projectData.MainImageXPos+'% '+projectData.MainImageYPos+'%'}"
      @click="closeMore"
    />
    <div
      class="moreContainer"
      :class="{opened: moreOpened}"
    >
      <div
        class="moreHeader"
        :class="{ 'opacity0': drawerOverlay, 'opacity1': !drawerOverlay }"
      >
        <div
          class="morebtn"
          :style="{color: projectData.TextfarbeBild}"
          @click="toggleMore"
        >
          {{ moreOpened ? '&nbsp;' : 'mehr Infos' }}
        </div>
      </div>

      <div class="moreContent">
        <div
          class="lessbtn"
          :style="{color: projectData.Highlightfarbe}"
          @click="toggleMore"
        >
          {{ moreOpened ? 'weniger Infos' : '&nbsp;' }}
        </div>
        <div
          id="scrollRef"
          ref="scrollRef" 
          class="moreContentWrapper" 
          @scroll.passive="handleScroll"
        >          
          <v-container class="moreContentInner">
            <v-row
              v-for="cSec in projectData.ContentArea"
              :key="cSec.id"
              class="section_row"
              :style="{marginTop: cSec.MarginTop+'px'}"
            >
              <ImageTextSection
                v-if="cSec.__component == 'blocks.image-text-row'"
                :data="cSec"
                :hlcolor="projectData.Highlightfarbe"
              />
              <LinksSection
                v-if="cSec.__component == 'blocks.link-row'"
                :data="cSec"
                :hlcolor="projectData.Highlightfarbe"
              />
            </v-row>            
          </v-container>
        </div>
        <div class="shadowBorder" />
        <v-container class="foot">
          <v-row>
            <v-col
              cols="6"
              sm-cols="6"
            >
              <div class="foot_contact">
                <a
                  href="mailto:julia@julia-wittich.de"
                  target="_blank"
                ><i class="fa-solid fa-envelope" /></a>
                <a
                  href="https://www.linkedin.com/in/wittichjulia/"
                  target="_blank"
                ><i class="fa-brands fa-linkedin" /></a>
                <a
                  href="https://www.xing.com/profile/Julia_Wittich4/cv"
                  target="_blank"
                ><i class="fa-brands fa-xing" /></a>
                <a
                  href="https://www.instagram.com/jules.wi/"
                  target="_blank"
                ><i class="fa-brands fa-instagram" /></a>
                <a
                  href="http://www.behance.net/wittichjulia"
                  target="_blank"
                ><i class="fa-brands fa-behance" /></a>
              </div>
            </v-col>
            <v-col
              cols="6"
              sm-cols="6"
            >
              <div class="foot_copy">
                &copy; Julia Wittich, {{ new Date().getFullYear() }}
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
    import axios from 'axios';
    import VLazyImage from "v-lazy-image";
    import ImageTextSection from './content/ImageTextSection.vue'
    import LinksSection from './content/LinksSection.vue'

    let uuid = 0;

    export default {
        name: 'ProjectComp',
        components: {
            VLazyImage,
            ImageTextSection,
            LinksSection,
        },
        props: {
            id: Number,
            sid: Number,
            bgcol: String,
            isactive: Boolean,
        },
        data() {
            return {
                projectData: {},
                loaded: false,
                moreOpened: false,
                drawerOverlay: false,
                scrollPos: -10,
                uuid: -1,
                sTOfn: undefined,
                sTCfn: undefined,
                dOfn: undefined,
                dCfn: undefined,
                interval: -1    
            }
        },
        created() {
            this.uuid = uuid.toString();
            uuid += 1;            
            axios.get(this.$api + '/api/projects/' + this.id + '?populate=deep').then(response => {
                //create project elements
                this.projectData = response.data.data.attributes;
                this.loaded = true;
            });
            let uri = window.location.search.substring(1);
            let params = new URLSearchParams(uri);
            let moreFromUrl = params.get("more");
            let sidFromUrl = params.get("slide");
            if (moreFromUrl == "true" && sidFromUrl == this.sid) this.moreOpened = true;
        },
        mounted() {
            this.$dom.i2svg()

            this.sTOfn = this.$bus.on("swipeToOpen", () => {
                if (!this.moreOpened && this.$root.curID == this.sid && this.isactive) this.toggleMore();
            })
            this.sTCfn = this.$bus.on("swipeToClose", () => {
                if(!this.moreOpened || this.$root.curID != this.sid || !this.isactive) return;
                this.swipeToClose()
            })
            this.dOfn = this.$bus.on("drawerOpen", () => {
                this.drawerOverlay = true;
                this.closeMore();
            })
            this.dCfn =this.$bus.on("drawerClose", () => {
                this.drawerOverlay = false;
            })
        },
        beforeUnmount() {
            this.$bus.off("swipeToOpen", this.sTOfn)
            this.$bus.off("swipeToClose", this.sTCfn)
            this.$bus.off("drawerOpen", this.dOfn)
            this.$bus.off("drawerClose", this.dCfn)
            clearInterval(this.interval)
        },
        methods: {
            swipeToClose: function () {
                if (this.moreOpened && this.scrollPos == 0 && this.isactive) this.toggleMore();
            },
            toggleMore: function () {
                this.moreOpened = !this.moreOpened
                if (this.moreOpened) {
                    this.$emit('moreOpened')
                    this.$refs.scrollRef.scrollTo(0,0);
                }
                else this.$emit('moreClosed')
            },
            closeMore() {
                this.moreOpened = false
                this.$emit('moreClosed')
            },
            formatCats(catStr, mobile) {
                if (catStr == undefined) return "loading...";
                if (mobile) return catStr.replace(/\n/gi, "<br/>")
                else return catStr.replace(/\n/gi, "&nbsp|&nbsp;")
            },
            getNumCats(catStr) {
                return catStr.split(/\n/gi).length
            },
            handleScroll(e) {
                this.scrollPos = e.target.scrollTop;
            }
        }
    }
</script>

<style scoped>
    img {
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -9999;
        width: 100vw;
        height: 100%;
        height: fill-available;
        height: -webkit-fill-available;
    }

    .v-lazy-image {
        filter: blur(10px);
        transform: scale(1.05);
        transition: all 1.2s;
        height:100%;
    }

    .v-lazy-image-loaded {
        transform: scale(1.0);
        filter: blur(0);
    }

    .container {
        width: 100vw;
        height:100%;
        /*background-color: v-bind(bgcol);*/
    }

    .moreContainer {
        width:100%;
        height:90vh;
        position: absolute;
        bottom: 0;
    }

    .cat {
        display: inline-block;
        color: white;
        font-family: ibmplexmono-bold;
        font-size: 15px;
        /*text-shadow: 1px 1px 8px #000000;*/
        user-select: none;
        transition: font-size 1s;
    }

    .cat.shrink {
        font-size: 12px;
        transition: font-size 1s;
    }

    .catWrapper {
        height: 10vh;
    }

    .morebtn {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-family: ibmplexmono-bold;
        font-size: 15px;
        position: absolute;
        bottom: 0vh;
        text-align: center;
        width: 100%;
        /*text-shadow: 1px 1px 8px #000000;*/
        user-select: none;
        background-color: transparent;
        height: 10vh;
        cursor: pointer;
        transform: scale(0.2) translateY(2vh);
        transition: all 2s ease-in-out 0.5s;
    }

    .v-window-item--active .morebtn {
        transform: scale(1.0) translateY(0vh);        
    }

    .lessbtn {
        display: block;
        color: black;
        font-family: ibmplexmono-bold;
        font-size: 15px;
        flex: 0 1 5vh;
        line-height: 5vh;
        margin-bottom: 10px;
        user-select: none;
        cursor: pointer;
    }

    .opacity0 {
        opacity: 0;
        transition: opacity 0.5s;
    }

    .opacity1 {
        opacity: 1;
        transition: opacity 0.5s;
    }

    .moreContent {
        position: absolute;
        display:flex;
        flex-flow: column;
        padding-top: 10px;
        padding-bottom: 0px;
        top: 100%;
        width: 100vw;
        background-color: white;
        transition: top 1.0s;
        height: 90vh;
    }

    .moreContentWrapper {
        overflow-x: hidden;
        overflow-y: auto;
        flex: 1 1 auto;
    }

    .moreHeader {
        margin-bottom: 80px;
    }

    .section_row {
        margin-bottom: 5vh;
    }

    .moreContentInner {
        text-align: left;
        max-width: 1200px;
    }

    .shadowBorder {
        flex: 0 0 3px;
        margin-top: -2px;
        background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
        width: 100%;
        z-index: 20000;
    }

    .foot_contact, .foot_contact a {
        text-align: left;
        color: gray;
        margin-right:0.6em;        
    }

    .foot_copy {
        text-align: right;
        color: gray;
    }

    .foot {
        flex: 0 0 40px;
        height: 40px;
        padding-bottom: 0px;
        padding-top: 10px;
        max-width: 1200px;
        justify-content: center;
    }

    .opened .moreContent {
        top: 0;
        transition: top 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);
        z-index: 10000;
    }
</style>