<template>
  <div
    class="container d-flex"
    :class="{'maximized': isMaximized, 'borderRemove': $vuetify.display.smAndDown}"
  >
    <div
      class="scalepart content flex-grow-1"
      :class="{'maximized': isMaximized}"
    >
      <div class="scrollcontainer" ref="scrollcontainer">
        <v-container
          v-if="projectData != undefined"
          class="moreContentInner"
        >
          <v-row
            v-for="cSec in projectData.ContentDyn"
            :key="cSec.id"
            class="section_row"
            :style="{marginTop: cSec.MarginTop+'px'}"
          >
            <ImageTextSection
              v-if="cSec.__component == 'blocks.image-text-row'"
              :data="cSec"
              :hlcolor="projectData.Highlightfarbe"
            />
            <LinksSection
              v-if="cSec.__component == 'blocks.link-row'"
              :data="cSec"
              :hlcolor="projectData.Highlightfarbe"
            />
          </v-row>
        </v-container>
      </div>
    </div>
    <div
      class="titlepart"
      :class="{'maximized': isMaximized}"
      @click="maximizeDrawer"
    >
      <span v-if="projectData != undefined">{{ projectData.TabLabel }}</span>
      <div class="close">✕</div>
    </div>
  </div>
</template>

<script>
    import gsap from 'gsap'
    import axios from 'axios';
    import ImageTextSection from './content/ImageTextSection.vue'
    import LinksSection from './content/LinksSection.vue'

    export default {
        name: 'DrawerComp',
        components: {
            ImageTextSection,
            LinksSection,
        },
        props: {
            pos: Number,
            title: String,
            secondary: Boolean,
            width: {
                type: String,
                default: "70px"
            },
            contentId: {
                type: String,
                default: "custom404"
            },
        },
        emits: {
          'maximized': null
        },
        data() {
            return {
                isMaximized: false,
                projectData: undefined,
            }
        },
        computed: {

        },
        created() {
            //Load content
            axios.get(this.$api + '/api/' + this.contentId + '?populate=deep').then(response => {
                this.projectData = response.data.data.attributes;
                this.loaded = true;
                this.$dom.i2svg();
            });
        },
        mounted() {
          this.$bus.on("maximized", (e) => {
            console.log("Bus incoming maximized (own state "+this.isMaximized+", max'd event for id "+e+", own id "+this.pos+")")
            if(this.isMaximized && e != this.pos) this.isMaximized = false;
          })
        },
        methods: {
            maximizeDrawer: function () {
                if (!this.isMaximized) {
                    this.isMaximized = !this.isMaximized
                    this.$emit('maximized', this.pos)
                    this.$bus.trigger('maximized', this.pos)  
                    this.$matomo.trackPageView(this.projectData.TabLabel)
                    this.$refs.scrollcontainer.scrollTo(0,0)                  
                } else {
                    this.$emit('minimized', this.pos)
                    this.isMaximized = !this.isMaximized
                }
            }
        }
    }
</script>

<style scoped>
    .container {
        display: flex;
        justify-content: center;
        text-align: left;
        width:1500px;
        max-width: calc(100vw - 350px);
        border-right: 3px solid black;
        border-bottom: 3px solid black;
        background-color: white;        
        padding:0;
        margin:0;
        box-sizing: border-box;
        transition: border 0.3s;
    }

    @media (max-width: 959px) {
        .container {
            max-width: 100vw;
        }
    }

    .container.maximized.borderRemove {
        border:0px solid black;
        transition: border 0.3s;
    }

    .scalepart {
        background-color: white;
        height: 100%;
        padding-left: 1rem;
        padding-top: 2rem;
    }

    .titlepart {
        vertical-align: top;
        width: v-bind(width);
        min-width: v-bind(width);
        min-height: 100%;
        font-size: 2rem;
        font-weight: 900;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: white;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        text-align: left;
        padding-top: 1rem;
        transform: rotate(-180deg);       
    }

    .close {
      opacity:0;
      text-align: center;      
      display: inline-block;
      position:absolute;
      bottom:0px;
      left:0px;
      line-height: v-bind(width);
      width:v-bind(width); 
      height:v-bind(width); 
      max-height: v-bind(width); 
      padding:0;
      margin: 0;
      pointer-events: none; 
    }
    .titlepart.maximized .close {
      transition: opacity 0.6s;
      transition-delay: 0.4s;
      opacity: 1;    
    }

    .scrollcontainer {
        overflow-y: auto;
        max-height: 100%;
    }

    .moreContentInner {
        text-align: left;
        max-width: 1200px;
    }
    
</style>