<template lang="">
  <!--
    <v-col v-for="l in data.SingleLink" :key="l.id" cols="12" md="4" class="linkblock">
        <a :href="l.LinkTarget"><span class="clickable" :style="{backgroundColor: this.hlcolor}"></span></a>
        <div class="theadl" :style="{color: this.hlcolor}">{{l.HeadlineTop}}</div>
        <v-lazy-image v-if="l.Image.data != null" :src="'https://jw-api.commcentral.de' +l.Image.data.attributes.url"></v-lazy-image>
        <div class="sheadl d-none d-md-block">{{l.HeadlineSub}}</div>
        <div class="desc d-none d-md-block">{{l.Description}}<span class="theadl" :style="{color: this.hlcolor}">&nbsp;↗</span></div>
    </v-col>
    -->
  <v-container>
    <v-row
      v-masonry
      class="linkblock"
    >
      <v-col
        v-for="l in data.SingleLink"
        :key="l.id"
        cols="12"
        sm="4"
      >
        <a :href="l.LinkTarget"><span
          class="clickable"
          :style="{backgroundColor: hlcolor}"
        /></a>
        <div
          class="theadl"
          :style="{color: hlcolor}"
        >
          <span
            class="theadl d-md-none"
            :style="{color: hlcolor}"
          >↗&nbsp;</span>{{ l.HeadlineTop }}
        </div>
        <v-lazy-image
          v-if="l.Image.data != null"
          :src="$api + l.Image.data.attributes.url" 
          :srcset="
            $api + l.Image.data.attributes.formats.small.url+' 500w,'+
              $api + l.Image.data.attributes.formats.medium.url+' 750w,'+
              $api + l.Image.data.attributes.formats.large.url+' 1000w,'+
              $api + l.Image.data.attributes.url+' 1200w'
          "
          @load="$redrawVueMasonry()"
        />
        <div class="sheadl">
          {{ l.HeadlineSub }}
        </div>
        <div class="desc d-none d-md-block">
          {{ l.Description }}<span
            class="theadl"
            :style="{color: hlcolor}"
          >&nbsp;↗</span>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
    import VLazyImage from "v-lazy-image";

    export default {
        components: {// eslint-disable-next-line
            VLazyImage
        },
        props: {
            data: Object,
            hlcolor: String
        },
        mounted() {
            console.log("Link section data");
            console.log(this.data);
        },
        updated() {
            this.$redrawVueMasonry()
        }
    }
</script>
<style scoped>
    .linkblock {
        position: relative;
    }

    .linkblock img {
        width: 100%;
    }

    .clickable {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        z-index: 1;
        opacity: 0;
    }

    .clickable:hover {
        opacity: 0.15;
    }

    .theadl {
        color: rgba(220, 20, 20, 1.0);
        font-size: 10pt;
        line-height: 26pt;
    }

    .sheadl {
        font-size: 13pt;
    }

    .desc {
        font-family: ibmplexsans-regular, sans-serif;
    }
</style>